import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from "../utility/api"

const initialState = {
    categories: []
}
export const getCategories = createAsyncThunk('getCategories', async () => {
    try {
        const response = await api().get('/categories');
        return response.data;
    } catch (err) {
        console.log(err);
    }
})
const category = createSlice({
    name: 'category',
    initialState,
    reducer: {},
    extraReducers: (builder) => {
        builder.addCase(getCategories.fulfilled, (state, action) => {
            state.categories = action.payload;
        })
    }
})
export default category.reducer;