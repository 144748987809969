import { useDispatch, useSelector } from "react-redux";
import Filter from "../../components/filter/Filter";
import { useEffect } from "react";
import { getEvents } from "../../redux/eventSlice";
import Events from "../../components/events/Events";

export default function Event() {
    const dispatch = useDispatch();
    const events = useSelector(state => state.event.events);
    const windowWidth = window.innerWidth;
    useEffect(() => {
        dispatch(getEvents());
    }, [])
    // if (this.state.viewport.width > 900) {
    //     style = {width: '45%', margin: '2.5%'};
    //   }
    //   else {
    //     style = {width: '100%', margin: '0'};
    //   }
    const style = {
        display: 'flex',
        margin: 'auto',
        width: window.innerWidth > 1250 ? 1250 : '90%',
    }
    return (

        <div style={style}>
            <div>
                <Filter />
            </div>
            <Events data={events} notObserved={windowWidth > 1088 ? 3 : 0} />
        </div>
    )
}