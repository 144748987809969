import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from "../utility/api";

const initialState = {
    popularEvents: [],
    // eventsBackup: [],
    // eventDetail: {},
    loading: false,
    error: '',
    message: '',
    status: ''
}
export const getPopularEvents = createAsyncThunk('getPopularEvents', async () => {
    try {
        const response = await api().get('/events/pop');
        return response.data;
    } catch (err) {
        console.log(err);
    }
})

export const getEventById = createAsyncThunk('getEventById', async (id) => {
    try {
        const response = await api().get(`/event/pop/${id}`);
        return response.data
    } catch (err) {
        console.log(err);
    }
})
export const addPopularEvent = createAsyncThunk('addPopularEvent', async (data) => {
    try {
        const response = await api().post('/event/pop', data, { withCredentials: true });
        return  data;
    } catch (err) {
        console.log(err);
    }
})
export const deletePopularEvent = createAsyncThunk('deletePopularEvent', async (data) => {
    try {
        const response = await api().delete(`/event/pop/${data.id}`, { withCredentials: true });
        return { response: response.data, id: data.id };
    } catch (err) {
        console.log(err);
    }
})


const popular = createSlice({
    name: 'popular',
    initialState,
    reducers: {
        clearStatus(state) {
            state.status = '';
        },
    }, extraReducers: (builder) => {
        //GET
        builder.addCase(getPopularEvents.pending, (state, action) => {
            state.loading = true;
            state.error = ''
        })
        builder.addCase(getPopularEvents.fulfilled, (state, action) => {
            state.loading = false;
            state.popularEvents = action.payload;
        })
        builder.addCase(getPopularEvents.rejected, (state, action) => {
            state.loading = false;
            state.error = 'get getEvents error';
        })
        // builder.addCase(getEventById.pending, (state, action) => {
        //     state.loading = true;
        //     state.error = '';
        // })
        // builder.addCase(getEventById.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.eventDetail = action.payload;
        // })
        // builder.addCase(getEventById.rejected, (state, action) => {
        //     state.loading = false;
        //     state.error = 'get getElementByID error'
        // })

        //POST, DELETE ,PUT

        builder.addCase(addPopularEvent.pending, (state, action) => {
            state.loading = true;
            state.error = '';
        })
        builder.addCase(addPopularEvent.fulfilled, (state, action) => {
            // const message=action.payload.response?.message
            // state.message = message !== undefined ? message : " " ;
            // state.loading = false;
            //state.status = action.payload.response.status;
            state.popularEvents = [...state.popularEvents,action.payload] 
        })
        builder.addCase(addPopularEvent.rejected, (state, action) => {
            state.loading = false;
            state.error = '';
        })
        builder.addCase(deletePopularEvent.pending, (state, action) => {
            state.loading = true;
            state.error = '';
        })
        builder.addCase(deletePopularEvent.fulfilled, (state, action) => {
            state.loading = false;
            state.popularEvents = state.popularEvents.filter(e => e.id !== action.payload.id);
            // const message = action.payload.response.message
            // state.message = message !== undefined ? message : " ";
        })
        builder.addCase(deletePopularEvent.rejected, (state, action) => {
            state.loading = false;
            state.error = 'get deleteEvent error ';
        })

    }
})

export default popular.reducer;