import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    provinces: [],
    districts: []
}

export const getProvinces = createAsyncThunk('getProvinces', async () => {
    try {
        const response = await axios.get('https://turkiyeapi.herokuapp.com/api/v1/provinces');
        const provinces = response.data.data;
        provinces.unshift({ name: "İstanbul" });
        return provinces;
    } catch (err) {
        console.log(err);
    }
})

export const getDistricts = createAsyncThunk('getDistricts', async (province) => {
    try {
        const response = await axios.get(`https://turkiyeapi.herokuapp.com/api/v1/provinces?name=${province}`);
        return response.data.data[0].districts;
    } catch (err) {
        console.log(err);
    }
})

const location = createSlice({
    name: 'location',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getProvinces.fulfilled, (state, action) => {
            state.provinces = action.payload;
        })
        builder.addCase(getDistricts.fulfilled, (state, action) => {
            state.districts = action.payload;
        })
    }
})
export default location.reducer;

