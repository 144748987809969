import { useState } from "react"

export default function BetaInfo() {
    const [show, setShow] = useState(true);
    const handleOnClick = () => {
        setShow(false);
    }
    return (
        <>
            {
                show &&
                <div>
                    <button
                        onClick={handleOnClick}
                        style={{ color: 'white', position: 'absolute', right: 20, top: 5, backgroundColor: 'black', border: 'none', border: '1px solid white', padding: '0px 4px', borderRadius: 50 }}>x</button>
                    <marquee direction="left" behavior="scroll" scrollamount="5"
                        style={{
                           backgroundColor: 'black', color: 'white', marginBottom: -4
                        }}
                    >
                        <div 
                        style={{
                            margin:"14px 1px",paddingTop:15
                        }}
                        >
                            <span>⭐ Beta Sürümümüz yayında...</span>
                            <span style={{ paddingLeft: 300 }}>
                                İletişim kısmından önerilerinizi iletebilirsiniz.
                            </span>
                            <span style={{ paddingLeft: 300 }}>⭐ Beta Sürümümüz yayında...</span>
                            <span style={{ paddingLeft: 300 }}>
                                İletişim kısmından önerilerinizi iletebilirsiniz.
                            </span>
                        </div>
                    </marquee>
                </div>
            }
        </>
    )
}