import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import tiktok from '../assets/tiktok.png'
import twitter from '../assets/twitter.png'

export default function Footer() {
    return (
        <div>
            <div style={{ height: 100, backgroundColor: 'gray', marginTop: 50 }}>
                <div style={{ paddingTop: 40, width: 200, margin: 'auto', display: 'flex', justifyContent: 'space-around' }}>
                    <a href='https://www.instagram.com/eventverstr/' target="_blank"><InstagramIcon fontSize="large" style={{ color: 'lightgray' }} /> </a>
                    <a href="https://www.youtube.com/channel/UCJleTZur9GeGgXxYJVBA3mg" target="_blank"><YouTubeIcon fontSize="large" style={{ color: 'lightgray' }} /></a> 
                    <a href='https://www.linkedin.com/company/eventvers/' target="_blank"><LinkedInIcon fontSize="large" style={{ color: 'lightgray' }}/></a>
                    <a href='https://www.tiktok.com/@eventvers?lang=tr-TR' target="_blank"><img  height='25' style={{paddingTop:5,borderRadius:5}} src={tiktok}></img></a>
                    <a href='https://twitter.com/eventverstr?s=21' target="_blank"><img  height='25' style={{paddingTop:5,borderRadius:5,paddingLeft:5}} src={twitter}></img></a>
                </div>
                <div style={{ display: 'block', paddingTop: 60, textAlign: 'center', color: '#aaaaaa' }}>  © 2024 EVENTVERS |  Tüm Hakları Saklıdır.</div>
            </div>
            <div style={{ height: 100, backgroundColor: 'lightgray' }}></div>
        </div>
    )
}