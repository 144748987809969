import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCategories } from "../../redux/categorySlice";
import { getEventByPrice, getEvents, getEventsByCityAndCategoryId, getEventsByFilter } from "../../redux/eventSlice";
import './style.css'
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import CachedIcon from '@mui/icons-material/Cached';
export default function Filter() {
    const dispatch = useDispatch();
    const categories = useSelector(state => state.category.categories);

    const dataListStyle = {
        // padding: 5,
        // width: 184
    }
    const dataListInputStyle = {
        padding: 10,
        borderRadius: 10,
        border: '1px solid lightgray'
    }

    const [provinces, setProvinces] = useState();
    const [districts, setDistricts] = useState();
    const [province, setProvince] = useState("");
    const [district, setDistrict] = useState("");
    const [category, setCategory] = useState("");
    const [filterApiData, setFilterApiData] = useState();

    const [free, setFree] = useState(false);
    const [paid, setPaid] = useState(false);
    const [online, setOnline] = useState(false);




    // const getProvinces = async () => {
    //     await axios.get('https://turkiyeapi.herokuapp.com/api/v1/provinces')
    //         .then(response => {
    //             const provinces = response.data.data;
    //             provinces.unshift({ name: "İstanbul" });
    //             setProvinces(provinces);
    //         }).catch(err => {
    //             console.log(err);
    //         })
    // }

    useEffect(() => {
        dispatch(getCategories());
        axios.get('https://turkiyeapi.herokuapp.com/api/v1/provinces')
            .then(response => {
                const provinces = response.data.data;
                provinces.unshift({ name: "İstanbul" });
                setProvinces(provinces);
            }).catch(err => {
                console.log(err);
            })
    }, [])

    useEffect(() => {
        axios.get(`https://turkiyeapi.herokuapp.com/api/v1/provinces?name=${province}`)
            .then(response => {
                setDistricts(response.data.data[0].districts);
            }).catch(err => {
                console.log(err);
            })
    }, [province])



    const filterBtnHandle = async () => {
        if (province !== "" || category !== "") {
            await dispatch(getEventsByFilter(filterApiData))
        }
        await dispatch(getEventByPrice({ free, paid, online }))

    }

    const resetFilterBtnHandle = async () => {
        setFree(false);
        setPaid(false);
        setOnline(false);
        setProvince("");
        setCategory("");
        setDistrict("");
        setFilterApiData({});
        dispatch(getEvents());
    }

    const [open, setOpen] = useState(false);
    const openFilter = () => {
        const filter = document.querySelector('#filter');
        filter.classList.toggle('open-filter');
        setOpen(!open);
    }
    const windowWidth = window.innerWidth;
    return (

        <>
            <div id="filter-open-btn" onClick={() => openFilter()}>
                <TuneIcon />{open
                    //? "x" : "Filter"  

                    ? <CloseIcon />
                    : <div id="fbtn-text">Filtrele</div>
                }
            </div>
            <div id="filter">
                <div style={dataListStyle} className="filter-item">
                    <select style={dataListInputStyle}
                        value={category}
                        //onClick={() => dispatch(getCategories())} 
                        onChange={(e) => {
                            setCategory(e.target.value)
                            setFilterApiData({ ...filterApiData, categoryId: e.target.value })
                        }}
                    >
                        <option style={{ color: 'lightgray' }}>Kategori</option>
                        {categories.map(c => {
                            return (<option value={c.id}>{c.name}</option>)
                        })}
                    </select>
                </div>
                <div style={dataListStyle} className="filter-item">
                    <select style={dataListInputStyle}
                        disabled
                    //value={category}
                    // onClick={() => dispatch(getCategories())} 
                    // onChange={(e) => { setCategory(e.target.value) }}
                    >
                        <option style={{ color: 'lightgray' }}>Türkiye</option>
                        {categories.map(c => {
                            return (<option value={c.id}>{c.name}</option>)
                        })}
                    </select>
                </div>
                <div style={dataListStyle} className="filter-item">
                    <input list="city"
                        value={province}
                        className="datalist-input" placeholder="Şehir" style={dataListInputStyle}
                        //onClick={() => getProvinces()}
                        onChange={(e) => {
                            setProvince(e.target.value)
                            setFilterApiData({ ...filterApiData, city: e.target.value })
                        }} />
                    <datalist id="city" >
                        {
                            provinces?.map(p => {
                                return (
                                    <option value={p.name} />
                                )
                            })
                        }
                    </datalist>

                </div>

                <div style={dataListStyle} className="filter-item">
                    <input list="district" className="datalist-input" placeholder="İlçe" style={dataListInputStyle} value={district}
                        onChange={(e) => {
                            setDistrict(e.target.value)
                            setFilterApiData({ ...filterApiData, district: e.target.value })
                        }} />
                    <datalist id="district">
                        {
                            districts?.map(p => {
                                return (
                                    <option value={p.name} />
                                )
                            })
                        }
                    </datalist>
                </div>
                <div style={dataListStyle} className="filter-item">
                    <input type="checkbox" style={dataListInputStyle}
                        onChange={() => setPaid(!paid)}
                        checked={paid}
                    />
                    <label style={{ color: 'gray', paddingLeft: 5 }}>Ücretli</label>
                </div>
                <div style={dataListStyle} className="filter-item">
                    <input type="checkbox" style={dataListInputStyle}
                        onChange={() => setFree(!free)}
                        checked={free}
                    />
                    <label style={{ color: 'gray', paddingLeft: 5 }}>Ücretsiz</label>
                </div>
                <div style={dataListStyle} className="filter-item">
                    <input type="checkbox" style={dataListInputStyle}
                        onChange={() => setOnline(!online)}
                        checked={online}
                    />
                    <label style={{ color: 'gray', paddingLeft: 5 }}>Online</label>
                </div>
                <div id="filter-btn-container">
                    <button onClick={filterBtnHandle} id="filter-btn">Etkinlikleri Filtrele</button>
                    <button onClick={resetFilterBtnHandle} id="reset-filter-btn">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            Filtreyi Sıfırla <CachedIcon fontSize="small" style={{ color: 'white', marginLeft: 9 }} />
                        </div>
                    </button>
                </div>
            </div>
        </>
    )
}