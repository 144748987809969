import { Link, NavLink } from 'react-router-dom';
import logo from '../assets/logo4.png'
import { useSelector } from 'react-redux';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp';
import IntersectionObserverImage from './IntersectionObserverImage';
import PersonIcon from '@mui/icons-material/Person';
import { useState } from 'react';
export default function Header() {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const role = useSelector(state => state.auth.role);

    const clickBurger = () => {
        const burger = document.querySelector('#burger-menu');
        const nav = document.querySelector('#nav-home');
        burger.classList.toggle('close');
        nav.classList.toggle('open');
    }
    const [show, setShow] = useState();
    const clickAuth = () => {
        const authLinkBox = document.querySelector('#auth-link-box');
        authLinkBox.classList.toggle('open');
    }
    const userImg = localStorage.getItem('user_img');
    const email = localStorage.getItem('email');

    return (
        <header>
            <Link to='/' className="logo">
                {/* <img src={logo} id='logo'></img> */}
                <IntersectionObserverImage url={logo} id='logo' />
            </Link>
            <nav id='nav-header'>
                <div id='nav-home'>
                    <NavLink to="/">Ana Sayfa</NavLink>
                    <NavLink to="/events">Etkinlikler
                        <SearchSharpIcon sx={{ paddingTop: 0.5, paddingLeft: 0.5, width: 20 }} />
                    </NavLink>
                    <NavLink to="/contact">İletişim</NavLink>
                    {
                        isAuthenticated &&
                        <>
                            {(role == "admin" || role == "user") &&
                                <>
                                    <NavLink to="/add_event">Etkinlik Ekle</NavLink>
                                    <NavLink to="/user_event">Etkinliklerim</NavLink>
                                    {role == "admin" && <NavLink to="/admin_event">Admin Paneli</NavLink>}
                                </>
                            }
                            {/* <NavLink to="/logout">Çıkış Yap <ExitToAppSharpIcon sx={{ paddingTop: 0.5, width: 20 }} /></NavLink> */}

                        </>
                    }
                </div>
                {
                    // !isAuthenticated &&
                    <div id='nav-auth'
                    // onClick={() => clickAuth()}
                    >
                        <div id='on-hover-auth'></div>
                        <div id='auth' style={{ display: 'flex', backgroundColor: '#f76c39', padding: 5, borderRadius: 10 }}>
                            {userImg ? <img src={userImg} id='user-img' ></img>
                                : <PersonIcon Outlined style={{ color: 'white' }} fill />}
                            {/* {
                                isAuthenticated
                                    ? <img src={userImg} id='user-img' ></img>
                                    : <PersonIcon Outlined style={{ color: 'white' }} fill />
                            } */}
                            <p >{isAuthenticated ? "Hesabım" : "Giriş Yap"}</p>
                        </div>
                        <div id='auth-link-box' >
                            <div class="triangle"></div>
                            {
                                isAuthenticated ?
                                    <>
                                        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 14 }}>{email}</span>
                                        <NavLink className="logout-link" to="/logout">
                                            <div style={{
                                                display: 'flex', alignItems: 'center'
                                            }}>
                                                <span>Çıkış Yap</span>
                                                <ExitToAppSharpIcon sx={{ width: 16, marginLeft: 1, color: 'gray' }} /></div>
                                        </NavLink>
                                    </>
                                    : <>
                                        <NavLink className="auth-link" to="/login">Giriş Yap</NavLink>
                                        <NavLink className="auth-link" to="/register">Kayıt Ol</NavLink>
                                    </>
                            }
                        </div>
                    </div>

                }
                <div id='burger-menu' onClick={() => clickBurger()}>
                    <div className='line' id='line1'></div>
                    <div className='line' id='line2'></div>
                    <div className='line' id='line3'></div>
                </div>
            </nav>
            {/* <div id='user-img-container'>
                <img src={userImg} id='user-img' ></img>
            </div> */}
            {/* <div id='burger-menu' onClick={() => clickBurger()}>
                <div className='line' id='line1'></div>
                <div className='line' id='line2'></div>
                <div className='line' id='line3'></div>
            </div> */}
        </header>
    )
}