import { useSelector } from "react-redux";
import loader from '../assets/loader.gif'
import { useEffect } from "react";
import LoaderGift from "./Gifts/loaderGift/LoaderGift";
export default function Button({ name, onSubmit, loading }) {
    const authLoading = useSelector(state => state.auth.loading);
    const eventLoading = useSelector(state => state.event.loading);
    const buttonStyle = {
        backgroundColor: 'rgb(250, 95, 39)',
        padding: authLoading || eventLoading || loading ? 0 : 14,
        width: '100%',
        borderRadius: 16,
        marginTop: 15,
        color: 'white',
        fontWeight: 600,
        fontSize: 14,
        border: 'none',
        display: 'block'

    }

    return (
        <button style={buttonStyle} onClick={onSubmit}>
            {authLoading || eventLoading || loading
                ? <LoaderGift color={"white"} />
                : name}
        </button>
    )
}