import './style.css'
import React from 'react'; // React'ı içe aktarın

export default function LoaderGift({color}) {
  // Elementi seçmek için useRef kullanın
  const myElementRef = React.useRef(null);

  React.useEffect(() => {
    if (myElementRef.current) {
      myElementRef.current.style.setProperty('--my-after-color', color);
    }
  }, []);

  return (
    <div className="lds-dual-ring" ref={myElementRef}></div>
  );
}
