import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../utility/api'
import axios from 'axios';

const date = localStorage.getItem('eventvers_date');
const role = date ? date[date.length - 1] == 1 ? "admin" : "user" : "";
const initialState = {
    loading: false,
    error: '',
    loginMessage: '',
    registerMessage: '',
    isAuthenticated: localStorage.getItem('eventvers_date') ? true : false,
    role: localStorage.getItem('eventvers_date') ? role : false,
    status: ''
}

export const register = createAsyncThunk('Register', async (data) => {
    try {
        const response = await api().post('/register', data);
        return response.data;
    } catch (err) {
        console.log(err);
    }
});
export const login = createAsyncThunk('login', async (data) => {
    try {
        axios.defaults.withCredentials = true;
        const response = await api().post('/login', data);
        return response.data;
    } catch (err) {
        console.log(err);
    }
})
export const googleAuth = createAsyncThunk('googleAuth', async (data) => {
    const headers = { "Authorization": `Bearer ${data}` };
    try {
        const response = await api().post('/auth/google',
            //{headers}
            { token: data }
            , { withCredentials: true });
        return response.data;
    } catch (err) {
        console.log(err);
    }
})
export const logout = createAsyncThunk('logout', async (data) => {
    try {
        const response = await api().get('/logout', { withCredentials: true });
        return response.data;
    } catch (err) {
        console.log(err);
    }
})


const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(register.pending, (state, action) => {
            state.loading = true;
            state.error = ''
        })
        builder.addCase(register.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload.status == "success") {
                state.registerMessage = ""
                state.status = "success"
            } else {
                state.status = "failure"
                state.registerMessage = action.payload.message;
            }
        })
        builder.addCase(register.rejected, (state, action) => {
            state.loading = false;
            state.error = "get register rejected error"
        })
        builder.addCase(login.pending, (state, action) => {
            state.loading = true;
            state.error = '';
        })
        builder.addCase(login.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload.success) {
                state.isAuthenticated = true;
                localStorage.setItem('eventvers_date', "23-09-2023-000" + action.payload.r);
                localStorage.setItem('email', action.payload.email);
                state.role = action.payload.r == 1 ? "admin" : "user";
            } else {
                state.loginMessage = action.payload;
            }
        })
        builder.addCase(login.rejected, (state, action) => {
            state.loading = false;
            state.error = 'get login rejected error'
        })
        builder.addCase(googleAuth.pending, (state, action) => {
            state.loading = true;
            state.error = '';
        })
        builder.addCase(googleAuth.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload?.success) {
                state.isAuthenticated = true;
                localStorage.setItem('eventvers_date', "23-09-2023-000" + action.payload.r);
                localStorage.setItem('user_img', action.payload.userImg);
                localStorage.setItem('email', action.payload.email);
                state.role = action.payload.r == 1 ? "admin" : "user";
            } else {
                state.loginMessage = action.payload;
            }
        })
        builder.addCase(googleAuth.rejected, (state, action) => {
            state.loading = false;
            state.error = 'get login rejected error'
        })
        builder.addCase(logout.pending, (state, action) => {
            state.loading = true;
            state.error = '';
        })
        builder.addCase(logout.fulfilled, (state, action) => {
            console.log(action.payload);
            state.loading = false;
            if (action.payload == "success") {
                state.isAuthenticated = false;
                localStorage.removeItem('eventvers_date');
                localStorage.removeItem('user_img');
                localStorage.removeItem('email');
                state.message = "";
            }
        })
        builder.addCase(logout.rejected, (state, action) => {
            state.loading = false;
            state.error = 'get login rejected error'
        })

    }
})
export default auth.reducer;
