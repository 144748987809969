import { Link } from "react-router-dom"
import PlaceIcon from '@mui/icons-material/Place';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import './style.css';
import MomentDate from '../MomentDate'
import IntersectionObserverImage from "../IntersectionObserverImage";
export default function Events({ data, notObserved }) {

    return (
        <div id="events-container">
            {data?.map(item => {

                return (
                    <div key={item?.id} id="cart">
                        {/* {
                            data.findIndex(obj => obj.id === item.id) <= notObserved
                                //data[notObserved]?.id == item.id
                                ?
                                //console.log(item)
                                <img src={item.imgUrl} id="cart-img"></img>

                                : <IntersectionObserverImage url={item.imgUrl} id={"cart-img"} />
                        } */}
                        {/* <img src={item.imgUrl} id="cart-img"></img> */}
                        <IntersectionObserverImage url={item.imgUrl} id={"cart-img"} />
                        <div id="cart-body">
                            <h3 id="cart-title">{item.name}</h3>
                            <div><CalendarMonthIcon sx={{ color: "lightgray", width: 20 }} /><p><MomentDate date={item.eventDate} /></p></div>
                            <div id="cart-price">{item.price == 0 ? "Ücretsiz" : item.price + " TL"}</div>
                            <div id="cart-address"><PlaceIcon sx={{ color: "lightgray", width: 20 }} />
                                {item.address == "Online"
                                    ? <p>Online</p>
                                    : <p>{item.city} / {item.district}</p>
                                }
                            </div>
                        </div>
                        <Link id="cart-detail" to={`/event/${item.id}`}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span>Başvuru ve Detaylar</span>
                                <KeyboardArrowRightIcon sx={{ display: 'block' }} />
                            </div>
                        </Link>
                    </div>
                )
            })
            }
        </div>
    )

}