import Button from "../../components/Button"
import {  useNavigate } from 'react-router-dom';
export default function Confirmed() {
    const navigate=useNavigate()
    const handleButton=()=>{
          navigate('/login');
    }
    return (
        <div style={{ minHeight:700}}>
            <div style={{ width: 'max-content', margin: '100px auto' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ConfirmIcon />
                </div>
                <h2 style={{ marginTop: 20 }}>
                    Hesabınız Doğrulandı.
                </h2>
                <Button onSubmit={()=>{handleButton()}} name={"Giriş yap"}/>
            </div>
        </div>
    )
}

const ConfirmIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="112"
            height="112"
            x="0"
            y="0"
            viewBox="0 0 512 512"
            style={{ enableBackground: 'new 0 0 512 512', margin: 'auto' }}
            xmlSpace="preserve"
        >
            {/* Your SVG paths go here */}
            {/* Example: */}
            <path
                fill="#fa5f27"
                fillRule="evenodd"
                d="M256 0C114.745 0 0 114.746 0 256c0 141.254 114.745 256 256 256 141.254 0 256-114.746 256-256C512 114.746 397.254 0 256 0z"
                clipRule="evenodd"
                opacity="1"
                data-original="#66bb6a"
            />
            <path fill="#f56a38" fill-rule="evenodd" d="M256 0c-3.69 0-7.35.124-11 .28C381.158 6.056 490 118.434 490 256S381.158 505.942 245 511.72c3.65.155 7.31.28 11 .28 141.254 0 256-114.746 256-256C512 114.746 397.254 0 256 0z" clip-rule="evenodd" opacity="1" data-original="#4caf50" class=""></path>
            <path fill="#e8f5e9" d="M403.058 126a35.516 35.516 0 0 0-25.112 10.414L206.978 307.537l-72.928-72.994a11.001 11.001 0 0 0-.002-.002c-13.773-13.783-36.447-13.783-50.22 0a11.001 11.001 0 0 0-.002.002c-13.77 13.785-13.77 36.453 0 50.238a11.001 11.001 0 0 0 .002 0l85.785 85.863c20.455 20.473 54.277 20.474 74.732 0l183.826-183.992c13.77-13.785 13.77-36.453 0-50.238A35.514 35.514 0 0 0 403.06 126a11.001 11.001 0 0 0-.003 0z" opacity="1" data-original="#e8f5e9" class=""></path>
            <path fill="#c8e6c9" d="M403.058 126c-3.772 0-7.475.629-10.998 1.78 5.27 1.72 10.123 4.641 14.111 8.634 13.77 13.785 13.77 36.453 0 50.238L222.345 370.644c-7.445 7.452-16.668 12.157-26.365 14.184 16.946 3.542 35.355-1.162 48.365-14.184l183.826-183.992c13.77-13.785 13.77-36.453 0-50.238A35.514 35.514 0 0 0 403.062 126z" opacity="1" data-original="#c8e6c9"></path>
            {/* Add other paths as needed */}
        </svg>
    )
}
