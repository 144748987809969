import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export default function PrivateRoute({ children, roles }) {

    const { isAuthenticated, role } = useSelector(state => state.auth);
   
    if (isAuthenticated && roles.includes(role)) {
        return children
    } else {
        return <Navigate to="/login" replace={true} />
    }

}