import './style.css'
export default function ScrollGift() {
    function scrollDown() {
        window.scrollBy(0, 700);
      }
    return (
        <div onClick={scrollDown} className='scroll-gift'>
            {/* <a href=""> */}
            <div class="scroll-down"></div>
        {/* </a> */}
        </div>
    )
}