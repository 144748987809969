import { Link } from "react-router-dom";
import Button from "../../components/Button";

export default function VerifyMessage() {
    return (
        <div style={{ textAlign: 'center', alignItems: 'center', height: 650 ,paddingTop:100,color:'gray'}}>
            Lütfen Giriş yapmadan önce Mail hesabınıza gelen bağlantı ile hesabınızı doğrulayınız.
            <div style={{paddingTop:30}}>
                Eğer Doğruladıysanız <Link to="/login" style={{color:"rgb(250, 95, 39)",fontSize:20}}> Giriş yap </Link> 
            </div>
        </div>
    )
}