import { configureStore } from "@reduxjs/toolkit";
import auth from './authSlice';
import event from './eventSlice';
import category from './categorySlice';
import popular from './popularSlice';
import location from './locationSlice';
import user from './userEventsSlice';


const store = configureStore({
    reducer: {
        auth,
        event,
        category,
        popular,
        location,
        user
    }
})
export default store;