import { useEffect, useState } from "react";
import logo from '../assets/logo4.png'
export default function IntersectionObserverImage({ url, id }) {

    const [isLoading, setIsLoading] = useState(true);

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    useEffect(()=>{
        const config = {
            rootMargin: "0px 0px 0px 0px",
            threshold: 0.2
        };
    
        const imgs = document.querySelectorAll(".observer-img");
        imgs.forEach((img) => {
            let observer = new window.IntersectionObserver((entries, self) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.src = entry.target.dataset.src;
                        self.unobserve(entry.target);
                    }
                })
            }, config)
            observer.observe(img);
        });
    },[])

    return (
        <div style={{ position: 'relative' }}>
            {isLoading &&
                <div style={{ position: 'absolute',backgroundColor:"rgb(223 221 221)", width: '100%', height: '100%', borderRadius: 20 }}>
                    <img src={logo} style={{width:'70%',display:'block',margin:'150px auto'}}></img>
                </div>}
            <img
                className='observer-img'
                id={id}
                src=""
                loading="lazy"
                data-src={url}
                onLoad={handleImageLoad}
            >
            </img>
        </div>
    )
}